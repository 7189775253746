import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  useLazyDeleteTutorByIdQuery,
  useLazyGetAllTutorListingQuery,
  useLazyTutorCsvQuery,
} from "../../services/tutor";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { successToast, errorToast } from "../../helpers";
import Loader from "../../helpers/Loader";
import WarnModal from "../../components/modals/WarnModal";
import Pagination from "../../components/Pagination";
import { usePutTutorProfileMutation } from "../../services/tutor";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validation";

const Deleted = () => {
 

  const [totalCount, setTotalCount] = useState<number>(0);
  console.log("ygygyygy", totalCount);
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(totalCount / 10);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const [rows, setRows] = useState<any>([]);
  const [value, setValue] = useState<number>(1);
  const [tututorStatust, settututorStatust] = useState<number>(3);

  const [getAllTutorList, { isLoading }] = useLazyGetAllTutorListingQuery();
 

  
  const fetchRejectedTutor = async () => {
    try {
      const response = await getAllTutorList({
        page: page,
        search: searchTerm,
        tutorStatus: "",
        isDelete:true
      }).unwrap();
      if (response.statusCode === 200) {
        setRows(response.data?.tutor);

        setTotalCount(response?.data?.totalTutor);
      }
    } catch (error) {
      console.error(error);
    }
  };



  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    // setRows([]);
  };




 

  
  useEffect(() => {
    {
      fetchRejectedTutor();
    }
  }, [page, searchTerm, tututorStatust, value]);
  return (
    <div>
      <Loader isLoad={isLoading} />
      <Box className="cards_header">
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
       
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Full Name</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Contact Number</TableCell>
              <TableCell align="center">Email</TableCell>
              
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row: any, i: any) => (
                <TableRow key={row?._id}>
                  
                  <TableCell align="center">
                    {(page - 1) * 10 + i + 1}
                  </TableCell>
                  <TableCell>{row?.name ? row?.name : "-"}</TableCell>
                  <TableCell>{row.userName ? row?.userName : "-"}</TableCell>
                  <TableCell>
                    {row?.phoneNo ? row?.dialCode + row.phoneNo : "-"}
                  </TableCell>
                  <TableCell>{row.email ? row?.email : "-"}</TableCell>
                  
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate(`/manage-tutors/details/${row._id}`, {
                            state: { userStatus: "DELETED" },
                          })
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No tutor found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={handleChangePage}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
     
    </div>
  );
};

export default Deleted;
